@use 'legacy/common/styles/config';
@use 'legacy/common/styles/colors';

.backdrop {
  width: 100%;
  height: 100%;

  position: absolute;

  z-index: 1;
  animation: fade-in 250ms ease;
}

.container {
  position: relative;
  background: colors.$white;

  width: 100%;
  height: 100%;

  z-index: 2;

  overflow: hidden;
  animation: slide-in 400ms ease;
  margin-top: 24px;
  padding-bottom: 24px;
  border-radius: 16px 16px 0 0;

  transition: transform 0.25s;

  &.alert {
    border-radius: 16px;

    width: 320px;
    height: auto;
  }

  &.transparent {
    background: transparent;
  }

  &.autoHeightOnMobile {
    position: absolute;
    bottom: 0;
    max-height: 100vh;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &.autoHeight {
    position: absolute;
    bottom: 0;
    height: auto;
    max-height: 95vh;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &.noPadding {
    padding: 0;
  }

  @media (min-width: config.$md) {
    position: relative !important;
    width: 750px;
    height: 670px;

    border-radius: 16px;

    &.small {
      width: 420px;
      height: 670px;
    }

    &.large {
      width: 500px;
      height: 670px;
    }

    &.verySmall {
      width: 420px;
      min-height: 500px;
      height: auto;
    }

    &.alert {
      width: 320px;
      height: auto;
    }

    &.fullScreen {
      width: 100%;
      height: 100%;
    }

    &.disableAnimationOnWeb {
      animation: none !important;
    }

    &.autoHeight {
      position: absolute;
      bottom: 0;
      height: auto;
      max-height: 670px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.6;
  }
}

@keyframes slide-in {
  0% {
    opacity: 0;
    transform: translateY(500px);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: translateY(0);
  }
}

.closeButton {
  position: absolute;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  background-color: #F0F3FF;
  padding: 9px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.isBehind {
  transform: scale(0.95) translateY(10px);
}
